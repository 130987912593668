@use 'sass:math';

// font-size class
$font-sizes: (4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42);
@each $size in $font-sizes {
    .fs-#{$size} {
        font-size: #{$size}px;
    }
}

$utilities: (
    'padding': (
        'prefix': 'r-p',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'padding-left': (
        'prefix': 'r-pl',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'padding-right': (
        'prefix': 'r-pr',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'padding-top': (
        'prefix': 'r-pt',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'padding-bottom': (
        'prefix': 'r-pb',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'margin': (
        'prefix': 'r-m',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'margin-left': (
        'prefix': 'r-ml',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'margin-right': (
        'prefix': 'r-mr',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'margin-top': (
        'prefix': 'r-mt',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'margin-bottom': (
        'prefix': 'r-mb',
        'values': (
            '0': 0,
            '1': $base-padding,
            '2': $base-padding * 2,
            '3': $base-padding * 3,
            '4': $base-padding * 4,
            '5': $base-padding * 5,
            '6': $base-padding * 6,
            '7': $base-padding * 7,
            '8': $base-padding * 8,
            '9': $base-padding * 9,
            '10': $base-padding * 10,
            '12': $base-padding * 12,
            '14': $base-padding * 14,
            '16': $base-padding * 16,
            '18': $base-padding * 18,
            '20': $base-padding * 20,
            '22': $base-padding * 22,
        ),
    ),
    'opacity': (
        'prefix': 'r-o',
        'values': (
            '10': 0.1,
            '20': 0.2,
            '30': 0.3,
            '40': 0.4,
            '50': 0.5,
            '60': 0.6,
            '70': 0.7,
            '80': 0.8,
            '90': 0.9,
            '100': 1,
        ),
    ),
    'border-radius': (
        'prefix': 'r-br',
        'values': (
            'default': $base-border-radius,
            'none': 0,
            '4': $base-border-radius * 2,
            '6': $base-border-radius * 3,
            '7': $base-border-radius * 3.5,
            '8': $base-border-radius * 4,
            '10': $base-border-radius * 5,
            '12': $base-border-radius * 6,
            '14': $base-border-radius * 7,
            '16': $base-border-radius * 8,
            '18': $base-border-radius * 9,
            'full': 50%,
        ),
    ),
    'display': (
        'prefix': 'display',
        'values': (
            'n': none,
            'b': block,
            'f': flex,
            'i': inline,
            'i-b': inline-block,
        ),
    ),
    'font-family': (
        'prefix': 'ff',
        'values': (
            'gr': Gilroy,
            'gm': Gilroy-Medium,
            'gl': Gilroy-Light,
            'gb': Gilroy-Bold,
            'gsb': Gilroy-SemiBold,
            'geb': Gilroy-ExtraBold,
        ),
    ),
);

// generate utility classes
@each $property, $map in $utilities {
    $prefix: map-get($map, 'prefix');
    $values: map-get($map, 'values');

    @each $k, $v in $values {
        @if ($k == 'default') {
            .#{$prefix} {
                #{$property}: $v;
            }
        } @else {
            .#{$prefix}-#{$k} {
                #{$property}: $v;
            }
        }
    }
}
