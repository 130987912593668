@import './breakpoints';
@import './_variables.scss';
@import './colors';
@import './grid';
@import './utilities';
@import './classes.scss';

.AuthenticatedApp {
    margin-left: 0px;
    padding: 20px 15px;
    background-color: #f7f7f7;
    min-height: 100vh;
}

.NavBarLinks {
    a.active {
        opacity: 1;
    }
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

#loading-spinner {
    display: inline;
    position: relative;
    z-index: 999999;

    .spin-icon {
        margin: auto;
        width: 15px;
        height: 15px;

        border: solid 3px transparent;
        border-top-color: #666;
        border-left-color: #666;
        border-radius: 80px;

        -webkit-animation: loading-spinner 500ms linear infinite;
        -moz-animation: loading-spinner 500ms linear infinite;
        -ms-animation: loading-spinner 500ms linear infinite;
        -o-animation: loading-spinner 500ms linear infinite;
        animation: loading-spinner 500ms linear infinite;
    }
}

@include sm {
    .AuthenticatedApp {
        padding: 40px 30px;
    }
}

@include md {
    .AuthenticatedApp {
        margin-left: 80px;
        padding: 40px 30px;
    }
}

@include lg {
    .AuthenticatedApp {
        margin-left: 80px;
        padding: 40px 40px;
    }
}

@include xl {
    .AuthenticatedApp {
        margin-left: 80px;
        padding: 40px 40px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        // transform: translateX(50px);
    }

    100% {
        opacity: 1;
        // transform: translateX(0px);
    }
}

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 15px;
    /* Avoid width reflow */
}
