.d-flex {
    display: flex;
    align-items: center;

    & > img {
        margin: 0px 10px 2px 0;
    }
}

.fs65 {
    font-size: 65px;
}

.fs48 {
    font-size: 48px;
}

.fs40 {
    font-size: 40px;
}

.fs36 {
    font-size: 36px;
}

.fs28 {
    font-size: 28px;
}

.fs26 {
    font-size: 26px;
}

.fs24 {
    font-size: 24px;
}

.fs22 {
    font-size: 22px;
}

.fs21 {
    font-size: 21px;
}

.fs20 {
    font-size: 20px;
}

.define1 {
    font-size: 18px;
}

.define {
    font-size: 14px;
}

.define2 {
    font-size: 16px;
}

.fs12 {
    font-size: 12px !important;
}

.fs10 {
    font-size: 10px;
}

.fs9 {
    font-size: 9px;
}

.fs8 {
    font-size: 8px;
}

/* font Weight Starts */
.fw6 {
    font-weight: 600;
}

.fw5 {
    font-weight: 500;
}

.fs14 {
    font-size: 14px;
}

.fw4 {
    font-weight: 400;
}

.steps,
.fw3 {
    font-weight: 300 !important;
}

.fw2 {
    font-weight: 200 !important;
}

/* font Color Starts */
.text-clr1 {
    color: #b9b9b9;
}

.text-clr2 {
    color: #999999;
}

.text-clr3 {
    color: #7c7c7c;
}

.text-clr4 {
    color: #ffffff;
}

.text-clr5 {
    color: #4f4f4f;
}

.clr7 {
    color: #070707;
}

.clr8 {
    color: #414141;
}

.clr9 {
    color: #4c8dff;
}

/* text-center */
.textc {
    text-align: center;
}

/* height */
.h85vh {
    height: 85vh;
}

/* width */
.w-maxc {
    width: max-content;
}

.w50px {
    width: 50px;
}

/* vertical align center */
.vc {
    top: 50%;
    transform: translateY(-50%);
}
