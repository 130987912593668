.EditClassification {
    .EditClassificationHeader {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        .EditClassificationBreadCrumb {
            font-family: 'Gilroy-Medium';
            font-size: 16px;
            line-height: 12px;

            .EditClassificationBreadCrumbClickable {
                color: #2f8fff;
            }

            & > strong {
                cursor: pointer;
            }
        }

        .EditClassificationButton {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-family: 'Gilroy-Medium';
            font-size: 12px;
            line-height: 12px;

            // animation: fadeIn 1s ease-out;
            & > img {
                margin: 0 8px 0px 0;
            }

            &:hover {
                font-family: 'Gilroy-Semibold';
            }
        }

        .SaveClassification {
            padding: 3px 3px 3px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-family: 'Gilroy-Medium';
            font-size: 12px;
            line-height: 12px;
            border: 1px solid black;
            border-radius: 4px;
            width: 350px;
            animation: fadeIn 200ms linear;

            position: sticky;
            top: 0;

            .ButtonContainer {
                display: flex;
                align-items: center;

                .CancelButton {
                    padding: 6px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid black;
                    border-radius: 4px;
                    cursor: pointer;

                    & > img {
                        margin: 0 5px 1px 0;
                    }

                    &:hover {
                        font-family: 'Gilroy-Semibold';
                    }
                }

                .SaveButton {
                    margin-left: 10px;
                    padding: 7px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: white;
                    background: black;
                    border: 1px solid #e5e5e5;
                    border-radius: 4px;
                    width: fit-content;
                    transition: all 1s linear;
                    cursor: pointer;

                    & > img {
                        margin: 0 5px 1px 0;
                    }

                    &:hover {
                        font-family: 'Gilroy-Semibold';
                    }
                }
            }
        }
    }

    .TableContainer {
        margin-top: 25px;
        padding: 0;
        border-radius: 8px;
        max-width: fit-content;
        margin-right: -20px;
        max-height: calc(100vh - 280px);
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 19px;
            scroll-padding-right: 100px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            margin: 48px 0 5px 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #656565;
            border-radius: 10px;
            border: 7px solid #f7f7f7;
        }

        .Table {
            border-collapse: separate;
            /* Don't collapse */
            border-spacing: 0;

            // max-width: 80vw;

            .TableHead {
                background-color: #f9fafc;
                font-family: 'Gilroy-SemiBold';
                font-size: 14px;
                line-height: 20px;
                color: #535b62;
                text-align: left;

                position: sticky;
                top: 0;

                .HeadMain {
                    background: #f9fafc;
                    min-width: 180px;
                    padding: 15px 0 15px 10px;
                    border-bottom: 1px solid #e5e5e5;
                    white-space: nowrap;
                    position: sticky;
                    left: 0;
                    z-index: 50;
                }

                .HeadSecond {
                    background: #f9fafc;
                    min-width: 150px;
                    padding: 15px 0 15px 10px;
                    border-bottom: 1px solid #e5e5e5;
                    white-space: nowrap;

                    position: sticky;
                    left: 180px;
                }

                .HeadThird {
                    background: #f9fafc;
                    min-width: 180px;
                    border-bottom: 1px solid #e5e5e5;
                    border-right: 1px solid #e5e5e5;
                    white-space: nowrap;

                    position: sticky;
                    left: 330px;
                }

                .HeadItem {
                    min-width: 100px;
                    border-bottom: 1px solid #e5e5e5;
                    text-align: left;
                    padding-left: 10px;
                }
            }

            .TableBody {
                font-size: 12px;
                line-height: 12px;

                td {
                    border-bottom: 1px solid #e5e5e5;
                    background-color: white;
                    padding: 10px 0;
                }

                .BodyMain {
                    padding-left: 10px;
                    font-family: 'Gilroy-Bold';
                }

                .BodyItem {
                    font-family: 'Gilroy-Medium';
                    padding-left: 10px;
                }

                .TotalRow {
                    position: sticky;
                    bottom: 0;

                    & > td {
                        border-top: 1px solid rgb(86, 86, 86);
                    }
                }

                .StickyFirst {
                    position: sticky;
                    left: 0;
                }

                .StickySecond {
                    position: sticky;
                    left: 180px;
                    padding-left: 10px;
                }

                .StickyThird {
                    position: sticky;
                    left: 330px;
                    border-right: 1px solid #e5e5e5;
                    padding-left: 0;
                }

                .TotalRow {
                    position: sticky;
                    bottom: 0;
                    z-index: 20;
                    font-family: 'Gilroy-SemiBold';

                    & > td {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        background-color: #e3e3e3;
                        border-top: 1px solid rgb(89, 88, 88);
                    }
                }

                img {
                    cursor: pointer;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        // transform: translateX(50px);
    }

    100% {
        opacity: 1;
        // transform: translateX(0px);
    }
}
