// @import "../../scss/main";

.dropdownLabel {
    font-size: 14px;
    color: #000000;
}

.Dropdown {
    // margin: 8px 10px;
    &__Main {
        padding: 12px 16px;
        // border: 1px solid #ababab;
        background-color: white;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        z-index: 50;
        // white-space: nowrap;
        width: 290px;

        div {
            border: none;
            padding: 10px 0;
            width: 102%;
        }

        &--Heading {
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
        }

        &--Toggle {
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;

            transition: background-color 0.5s linear;

            border-radius: 50%;

            &--Verticle {
                position: absolute;

                transition: transform 0.2s linear;

                &--Show {
                    transform: rotate(180deg);
                }

                &--Hide {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__Extended {
        overflow: auto;
        background: white;
        border: 1px solid #150101;
        box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.1);
        border-radius: 4px;
        padding: 0px;

        position: absolute;
        top: 100%;
        left: -2px;
        text-align: center;
        z-index: 10;

        &--Hide {
            // max-height: 0;
            display: none;
            transition: padding-top 0.2s cubic-bezier(0, 1, 0, 1),
                max-height 0.3s cubic-bezier(0, 1, 0, 1);
        }

        &--Show {
            display: block;
            transition: padding-top 0.3s linear, max-height 0.3s linear;
            margin-top: 4px;
            max-height: 170px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.147918);
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 10px;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                margin: 5px 0 5px 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
                border-radius: 4px;
                border: 3px solid #ffffff;
            }
        }

        &__item {
            padding: 8px 12px;
            &:hover {
                background: rgb(236, 236, 236);
            }
        }
    }
}
